import React from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import BusinessIcon from '@mui/icons-material/Business';

const EntitySwitcherButton = React.forwardRef((props, ref) => {
  const { ownerState, ...other } = props;

  return (
    <button type="button" {...other} ref={ref}>
      <BusinessIcon className="cai-entity-switcher-icon" />
      <div className="cai-entity-switcher-label">{other.children}</div>
      <ArrowDropDownIcon className="cai-entity-switcher-icon cai-entity-switcher-select-icon " />
    </button>
  );
});

export default EntitySwitcherButton;
