import React from 'react';
import cn from 'classnames';
import { Option } from '@mui/base';
import styled from '@emotion/styled';
import { find } from 'lodash';

import EntitySwitcherSelect from './components/entity-switcher-select';
import { cacheItem } from '../../../util/cache';
import { Translate } from '../../../components/localization';

import { ITEM_HEIGHT, OptionStyles } from './styles';

const StyledOption = styled(Option)`
  ${OptionStyles}
`;

const calculateHeight = documentHeight => Math.floor((documentHeight - ITEM_HEIGHT) / ITEM_HEIGHT) * ITEM_HEIGHT;

const renderValue = option => {
  if (option === null || option.id === '') {
    return <Translate stringId="selectCompany" data-testid="select-company" />;
  }

  return option.label;
};

const CaiEntitySwitcher = ({ entities, value, onChange }) => {
  const [listboxMaxHeight, setListboxMaxHeight] = React.useState(0);

  const handleChange = React.useCallback(
    (_, newValue) => {
      const entityName = find(entities, { entityRef: newValue })?.name;
      cacheItem('entityRef', newValue);
      onChange(newValue, entityName);
    },
    [entities, onChange]
  );

  const options = React.useMemo(
    () => entities?.map?.(option => ({ id: option.entityRef, label: option.name })) || [],
    [entities]
  );

  const selectedEntity = React.useMemo(() => {
    return find(options, { id: value || '' }) || '';
  }, [value, options]);

  /**
   * Calculate max items can be displayed based on the body height.
   * TODO:: Possible optimization using debounce
   */
  React.useEffect(() => {
    const handler = e => {
      setListboxMaxHeight(calculateHeight(document.body.clientHeight));
    };

    setListboxMaxHeight(calculateHeight(document.body.clientHeight));
    window.addEventListener('resize', handler);

    return () => {
      window.removeEventListener('resize', handler);
    };
  }, []);

  return (
    <EntitySwitcherSelect
      value={value}
      onChange={handleChange}
      renderValue={renderValue}
      slotProps={{
        listbox: { listboxMaxHeight: listboxMaxHeight },
        root: { className: cn({ 'one-option': options.length <= 1 }) },
      }}
    >
      {options.map(option => {
        const hide = option.id === selectedEntity.id;
        return (
          <StyledOption key={option.id} value={option.id} className={cn({ 'hide-option': hide })}>
            <span className="cai-entity-switcher-label">{option.label}</span>
          </StyledOption>
        );
      })}
    </EntitySwitcherSelect>
  );
};

export default CaiEntitySwitcher;
