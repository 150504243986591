import React from 'react';
import { useUserContext } from './UserContext';

export const _has = ({ permission, permissions, some = false, rolePermissions }) => {
  const permissionsArray = [].concat(permission || permissions);

  const strategy = some ? 'some' : 'every';

  console.log('_has rolePermissions', rolePermissions);

  if (rolePermissions?.includes?.('*')) return true;

  return permissionsArray?.[strategy]?.(p => rolePermissions?.includes?.(p));
};

class Else extends React.Component {
  render() {
    return this.props.children;
  }
}

const Has = ({ children, permission, permissions, some = false, not = false }) => {
  const childrenArray = React.Children.toArray(children);

  const regularChildren = childrenArray.filter(kid => kid.type !== Else);
  const elseChild = childrenArray.filter(kid => kid.type === Else);

  const { role } = useUserContext();

  const hasPermission =
    _has({ permission, permissions, some: some && !not, rolePermissions: role?.permissions }) !== not;

  return hasPermission ? regularChildren : elseChild?.[0] || null;
};

const PlatformSupport = ({ children }) => {
  const childrenArray = React.Children.toArray(children);

  const regularChildren = childrenArray.filter(kid => kid.type !== Else);
  const elseChild = childrenArray.filter(kid => kid.type === Else);

  const { user } = useUserContext();

  return user?.isPlatformSupport ? regularChildren : elseChild?.[0] || null;
};

const PlatformAdmin = ({ children }) => {
  const childrenArray = React.Children.toArray(children);

  const regularChildren = childrenArray.filter(kid => kid.type !== Else);
  const elseChild = childrenArray.filter(kid => kid.type === Else);

  const { user } = useUserContext();

  return user?.isPlatformAdmin ? regularChildren : elseChild?.[0] || null;
};

export const usePermissions = () => {
  const { user, role } = useUserContext();

  const rolePermissions = role?.permissions;

  const has = React.useCallback(permission => _has({ permission, rolePermissions }), [rolePermissions]);

  const hasEvery = React.useCallback(permission => _has({ permission, rolePermissions }), [rolePermissions]);

  const hasSome = React.useCallback(
    permission => !_has({ permission, rolePermissions, some: true }),
    [rolePermissions]
  );

  const hasNot = React.useCallback(permission => !_has({ permission, rolePermissions }), [rolePermissions]);

  const isPlatformAdmin = React.useMemo(() => !!user?.isPlatformAdmin, [user?.isPlatformAdmin]);

  const isPlatformSupport = React.useMemo(() => !!user?.isPlatformSupport, [user?.isPlatformSupport]);

  return {
    Has,
    Else,
    PlatformAdmin,
    PlatformSupport,
    has,
    hasEvery,
    hasNot,
    hasSome,
    isPlatformAdmin,
    isPlatformSupport,
  };
};
