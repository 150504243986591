import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import style from './style';
import iconAdmin from '../../../../assets/app-icon-admin.svg';
import iconDispatch from '../../../../assets/app-icon-dispatch.svg';
import iconAssurance from '../../../../assets/app-icon-assurance.svg';
import iconDeliveries from '../../../../assets/app-icon-deliveries.svg';
import iconPlatformAdmin from '../../../../assets/app-icon-platform-admin.svg';
import iconDefault from '../../../../assets/app-icon-default.svg';

const Styled = styled.div`
  ${style}
`;

const appRefToIconMap = {
  'dispatch-exp': iconDispatch,
  'company-admin': iconAdmin,
  assurance: iconAssurance,
  mt: iconDeliveries,
  admin: iconPlatformAdmin,
};

export const AppIcons = ({ apps, onClick, appContext }) => {
  const { allApps } = appContext;

  const filteredApps = React.useMemo(() => {
    if (!apps?.length) return [];

    const caiApps = allApps?.filter(app => app.type === 'CX_APP_CAI').map(app => app.crn);

    return (
      apps
        .filter(app => caiApps?.includes?.(app.appRef))
        .map(app => ({
          ...app,
          appIconImage: appRefToIconMap[app.appRef] || iconDefault,
        }))
        .sort(app => (appRefToIconMap[app.appRef] ? -1 : 1)) || []
    );
  }, [apps, allApps]);

  return (
    <Styled className={cn('app-icons')} $apps={apps}>
      <div className="app-icons-inner">
        {filteredApps?.map?.(app => (
          <div className="app-icon" onClick={() => onClick(app.appRef)} key={app.appRef}>
            <img src={app.appIconImage} alt={app.name} />
            {app.appIconImage === iconDefault ? <span className="app-name-default">{app.name}</span> : <></>}
          </div>
        ))}
      </div>
    </Styled>
  );
};
