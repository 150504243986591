import { selectClasses } from '@mui/base/Select';
import { optionClasses } from '@mui/base/Option';
import { css } from '@emotion/react';

export const ITEM_HEIGHT = 39;

export const ButtonStyles = ({ theme }) => css`
  z-index: 10;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  font-family: Work Sans;
  color: ${theme.colorsCA.neutral[100]};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  align-items: center;
  width: 386px;
  height: ${ITEM_HEIGHT}px;
  padding: 0px 37px;
  justify-content: space-between;
  flex-shrink: 0;
  background-color: ${theme.validateMode({ light: theme.colorsCA.darkBlue[10], dark: theme.colorsCA.darkBlue[60] })};
  border-radius: 0px 0px 39px 39px;
  border: none;

  &.${selectClasses.expanded}:not(.one-option) {
    border-radius: unset;

    .cai-entity-switcher-select-icon {
      transform: rotate(180deg);
    }
  }

  &:hover {
    background-color: ${theme.validateMode({ light: theme.colorsCA.darkBlue[40], dark: theme.colorsCA.darkBlue[50] })};
  }

  .cai-entity-switcher-icon {
    font-size: 18px;
  }

  .cai-entity-switcher-label {
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
  }

  &.one-option .cai-entity-switcher-select-icon {
    visibility: hidden;
  }
`;

export const ListboxStyles = ({ theme, listboxMaxHeight }) => css`
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: ${theme.colorsCA.neutral[100]};
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  overflow: auto;
  outline: 0px;
  border-radius: 0px 0px 39px 39px;
  max-height: ${listboxMaxHeight}px;

  .hide-option {
    display: none;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: ${theme.validateMode({ light: theme.colorsCA.darkBlue[10], dark: theme.colorsCA.darkBlue[60] })};
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  ::-webkit-scrollbar {
    width: 8px;
    background-color: ${theme.validateMode({ light: theme.colorsCA.darkBlue[10], dark: theme.colorsCA.darkBlue[60] })};
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${theme.colorsCA.neutral[100]};
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
`;

export const OptionStyles = ({ theme }) => css`
  display: flex;
  align-items: center;
  list-style: none;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding: 0px 37px;
  cursor: pointer;
  height: ${ITEM_HEIGHT}px;
  background-color: ${theme.validateMode({ light: theme.colorsCA.darkBlue[10], dark: theme.colorsCA.darkBlue[60] })};

  &.${optionClasses.selected} {
    background-color: ${theme.validateMode({ light: theme.colorsCA.darkBlue[40], dark: theme.colorsCA.darkBlue[50] })};
  }

  &.${optionClasses.highlighted}.${optionClasses.selected} {
    background-color: ${theme.validateMode({ light: theme.colorsCA.darkBlue[40], dark: theme.colorsCA.darkBlue[50] })};
  }

  &.${optionClasses.disabled} {
  }

  &:hover:not(.${optionClasses.disabled}) {
    background-color: ${theme.validateMode({ light: theme.colorsCA.darkBlue[40], dark: theme.colorsCA.darkBlue[50] })};
  }

  .cai-entity-switcher-label {
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
  }
`;

export const PopperStyles = ({ theme }) => css`
  z-index: 10;
  border-radius: 0px 0px 39px 39px;
  overflow: hidden;
  width: 386px;
  box-sizing: border-box;
  background-color: ${theme.validateMode({ light: theme.colorsCA.darkBlue[10], dark: theme.colorsCA.darkBlue[60] })};
  padding: 0 1px;
`;
