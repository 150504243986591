import React from 'react';
import { MaterialIcon } from '../../../components/icons';
import cn from 'classnames';
import { Styled } from './styles';

const MenuIcon = ({ iconName, onClick, active }) => {
  return (
    <Styled className={cn({ active })} onClick={onClick}>
      <MaterialIcon iconName={iconName} />
    </Styled>
  );
};

export default MenuIcon;
