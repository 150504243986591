import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const SidebarContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex-basis: 77px;
    margin-right: 10px;
    overflow: hidden;
    border-radius: 0 10px 10px 0;

    background-color: ${theme.validateMode({ light: '#F8FDFF' })};
    border-right: ${theme.validateMode({
      light: '1px solid #CAC4D0',
      dark: '1px solid #49454F',
    })};
    ${theme.validateMode({
      light: 'box-shadow: 4px 0px 8px 0px #65778833',
      dark: theme.elevationCA[3],
    })};

    &.has-children {
      border-radius: 0;
    }

    .sidebar-logo-container {
      display: flex;
      justify-content: center;
      height: 107px;
      padding-top: 16px;

      img {
        height: 44px;
        width: 44px;
      }
    }

    .sidebar-options-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .option {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 10px;
        margin-bottom: 22px;
        cursor: pointer;
        color: ${theme.validateMode({
          light: theme.colorsCA.neutral[40],
          dark: theme.colorsCA.neutral[95],
        })};

        &:hover {
          background-color: ${theme.validateMode({
            light: theme.colorsCA.darkBlue[95],
            dark: theme.colorsCA.neutral[30],
          })};
        }

        &__active {
          box-shadow: 0px 4px 4px 0px #00000040 inset;
          color: ${theme.colorsCA.neutral[95]};
          background-color: ${theme.validateMode({
            light: theme.colorsCA.darkBlue[70],
            dark: theme.colorsCA.darkBlue[60],
          })};

          &:hover {
            background-color: ${theme.validateMode({
              light: theme.colorsCA.darkBlue[70],
              dark: theme.colorsCA.darkBlue[60],
            })};
          }
        }

        &__children {
          &:hover {
            cursor: default;
          }
        }

        &__active-children {
          background-color: ${theme.validateMode({
            light: theme.colorsCA.darkBlue[95],
            dark: theme.colorsCA.neutral[30],
          })};
        }
      }

      .icon {
        height: 20px;
      }
    }

    .menu-sidebar {
      display: flex;
      flex-direction: column;
      position: absolute;
      width: 217px;
      left: 77px;
      height: 100%;
      z-index: 9999;
      border-radius: 0 10px 10px 0;
      background-color: ${theme.validateMode({ light: '#F8FDFF' })};
      transition-timing-function: ease-in;
      border-right: ${theme.validateMode({
        light: '1px solid #CAC4D0',
        dark: '1px solid #49454F',
      })};
      ${theme.validateMode({
        light: 'box-shadow: 4px 0px 8px 0px #65778833',
        dark: theme.elevationCA[3],
      })};

      .menu-title-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 18px 18px 0;

        .MuiSvgIcon-root {
          width: 24px;
          height: 24px;
        }

        span {
          font-size: 20px;
          font-weight: 700;
          margin-left: 10px;
        }
      }

      .menu-order-container {
        display: flex;
        min-height: 40px;
        width: 120px;
        border-width: 1px;
        border-style: solid;
        border-radius: 100px;
        margin: 20px 18px 0;

        .menu-order-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50%;
          border-radius: 100px 0 0 100px;
          cursor: pointer;

          .MuiSvgIcon-root {
            font-size: 18px;
          }

          &:hover {
            background-color: ${theme.validateMode({
              light: theme.colorsCA.darkBlue[95],
              dark: theme.colorsCA.neutral[30],
            })};
          }

          &:last-child {
            border-left-style: solid;
            border-left-width: 1px;
            border-radius: 0 100px 100px 0;
          }

          &__active {
            box-shadow: 0px 4px 4px 0px #00000040 inset;
            color: ${theme.colorsCA.neutral[95]};
            pointer-events: none;
            background-color: ${theme.validateMode({
              light: theme.colorsCA.darkBlue[70],
              dark: theme.colorsCA.darkBlue[60],
            })};
          }
        }
      }

      .menu-options-container {
        display: flex;
        flex-direction: column;
        overflow: auto;
        margin: 18px 0;
        padding: 0 18px;
      }

      .menu-option {
        padding: 14px;
        border-radius: 10px;
        cursor: pointer;
        color: ${theme.validateMode({
          light: theme.colorsCA.neutral[40],
          dark: theme.colorsCA.neutral[95],
        })};

        &:hover {
          background-color: ${theme.validateMode({
            light: theme.colorsCA.darkBlue[95],
            dark: theme.colorsCA.neutral[30],
          })};
        }

        &__active {
          box-shadow: 0px 4px 4px 0px #00000040 inset;
          color: ${theme.colorsCA.neutral[95]};
          pointer-events: none;
          background-color: ${theme.validateMode({
            light: theme.colorsCA.darkBlue[70],
            dark: theme.colorsCA.darkBlue[60],
          })};
        }

        &__children {
          &:hover {
            background-color: ${theme.validateMode({
              light: theme.colorsCA.darkBlue[95],
              dark: theme.colorsCA.neutral[30],
            })};
          }
        }

        span {
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
  `}
`;
