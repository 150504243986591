import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Avatar } from '@mui/material';

export const ProfileStyled = styled(Avatar)`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    background: radial-gradient(50% 50% at 50% 50%, #6b88ab 0%, #6b88ab 0%, #a1c0e5 0.01%, #86a3c7 99.48%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid ${theme.colorsCA.darkBlue[60]};
    cursor: pointer;
    color: ${theme.colorsCA.neutral[100]};
    text-align: center;
    font-size: 16px;
    font-weight: 600;
  `}
`;
