import { css } from 'styled-components';

export default css`
  &.cx-sidebar {
    height: 100%;
    display: flex;
    flex-direction: row;
    transition: background-color 0.3s;
    background-color: var(--primary-background-color);
    overflow: hidden;

    .sidebar {
      transition: flex-basis 0.3s;
      flex-basis: 0;

      &.visible {
        flex-basis: 138px;
      }
      overflow: hidden;
    }

    & > .content {
      display: flex;
      flex-direction: column;
      //transition: color 03s;
      background-color: var(--primary-background-color);
      flex: 1;
      margin-right: 60px;
      overflow-x: hidden;
      & > * {
        &:nth-child(2) {
          overflow: auto;
        }
      }
    }
  }

  &.open {
    &&& {
      .sidebar {
        flex-basis: 300px;
      }
    }
    .collapsible-sidebar {
      .outer {
        .inner {
          .logo-title {
            .title {
              transition: all 0.3s;
              width: 180px;
              top: 20px;
              left: 80px;

              //height: 103px;
              font-size: 1.2rem;

              title-inner {
                text-align: center;
                vertical-align: center;
              }
            }
          }
        }
      }
    }
  }

  &:not(.open) {
    .collapsible-sidebar {
      transition: all 0.3s;
      width: 138px;

      .toggle {
        right: 6px;
        .icon {
          transform: rotate(180deg);
        }
      }
      .outer {
        width: 108px;
        .inner {
          .logo-title {
            width: 108px;
            .title {
              width: 107px;
              font-size: 12px;
              title-inner {
                text-align: center;
              }
            }
          }
        }
      }
    }
  }

  .collapsible-sidebar {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 282px;
    transition: width 0.3s;
    margin-right: 57px;
    background-color: var(--primary-background-color);

    .toggle {
      position: absolute;
      bottom: 106px;
      right: 7px;
      box-shadow: var(--sidebar-trigger-box-shadow);
      height: 50px;
      width: 50px;
      background-color: var(--sidebar-background-color);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: right 0.3s;
      .icon {
        transition: transform 0.5s;
        font-size: 18px;
      }
    }

    .outer {
      background-color: var(--sidebar-background-color);
      box-shadow: var(--sidebar-box-shadow);
      border-radius: 0 10px 10px 0;
      display: flex;
      flex-direction: column;
      flex: 1;
      width: 272px;
      overflow: hidden;
      transition: all 0.3s;
      .inner {
        display: flex;
        flex-direction: column;
        flex: 1;

        width: 262px;

        .logo-title {
          position: relative;
          width: 262px;
          //border: 1px solid red;
          height: 100px;
          transition: all 0.3s;
          .logo {
            margin-left: 25px;
            margin-top: 34px;
            width: 57px;
            height: 35px;
          }
          .title {
            transition: all 0.3s;
            position: absolute;
            width: 100%;
            top: 55px;
            left: 0;
            font-weight: 600;
            .title-inner {
              transition: color 0.3s;
              text-align: center;
              text-transform: uppercase;
              padding: 5px;
              color: var(--sidebar-app-name-color);
            }
          }
        }

        .dynamic-menu-section {
          flex: 1;
        }
        .bottom {
        }
      }
    }

    .theme-toggle {
      position: fixed;
      bottom: 0px;
      left: 5px;
    }
  }
`;
