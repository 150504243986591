import React from 'react';
import { getCachedItem } from '../../../util/cache';
import { getPermittedMenuConfig } from '../../../util/getPermittedMenuConfig';
import { API } from '../../api';
import { verifyToken } from '../../api/common';
import { Spinner } from '../../core';
import { useUserContext } from '../../user';
import { useAuthContext } from '../AuthContext';
import { Authenticated } from './Authenticated';

export const IFrameAuthenticated = ({ authFn = verifyToken, entityRef, menuConfig }) => {
  const [busy, setBusy] = React.useState(true);
  const { isUserAuthorizedForEntity, setAuthorizedEntity } = useAuthContext();
  const userContext = useUserContext();

  const token = getCachedItem('x-connex-id');
  API.setLoginToken(token);

  React.useEffect(() => {
    setBusy(true);
    authFn?.(entityRef, token)
      ?.then?.(response => {
        setAuthorizedEntity(response.valid ? entityRef : false);
      })
      .finally(() => {
        setBusy(false);
      });
  }, [authFn, entityRef, setAuthorizedEntity, token]);

  const permittedMenuConfig = React.useMemo(() => {
    return getPermittedMenuConfig({ menuConfig: (menuConfig || {}).sidebarOptions, userContext });
  }, [menuConfig, userContext]);

  if (busy) return <Spinner spin={true} />;

  return isUserAuthorizedForEntity(entityRef) ? <Authenticated menuConfig={permittedMenuConfig} /> : null;
};
