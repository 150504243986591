export const getPermittedMenuConfig = ({ menuConfig = [], userContext }) => {
  const { user, role } = userContext;

  // Temporary workaround to support both menuConfig shapes until everyone has changed to the new format.
  const permitted = (menuConfig?.sidebarOptions || menuConfig).filter(config => {
    if (user?.isPlatformAdmin || user?.isPlatformSupport) return true;
    const configPermissions = [].concat(config?.permissions).filter(permission => !!permission);
    if (configPermissions?.length) {
      if (role?.permissions?.include?.('*')) return true;
      return configPermissions?.[config.permissionStrategy || 'some']?.(p => role?.permissions?.includes(p));
    }
    return true;
  });

  if (menuConfig.sidebarOptions) {
    return {
      ...menuConfig,
      sidebarOptions: permitted,
    };
  }

  return permitted;
};
