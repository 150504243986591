import React from 'react';
import { Popper, Select } from '@mui/base';
import styled from '@emotion/styled';

import EntitySwitcherButton from '../entity-switcher-button';

import { ButtonStyles, ListboxStyles, PopperStyles } from '../../styles';

const StyledListbox = styled.ul`
  ${ListboxStyles}
`;

const StyledPopper = styled(Popper)`
  ${PopperStyles}
`;

const StyledButton = styled(EntitySwitcherButton, { shouldForwardProp: () => true })`
  ${ButtonStyles}
`;

const EntitySwitcherSelect = React.forwardRef(function CustomSelect(props, ref) {
  const slots = {
    root: StyledButton,
    listbox: StyledListbox,
    popper: StyledPopper,
    ...props.slots,
  };

  return <Select {...props} ref={ref} slots={slots} />;
});

export default EntitySwitcherSelect;
