import { isEmpty, isUndefined } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import { Translate } from '../Translate';

export const Uom = ({ uom, short, testId }) => {
  if (isEmpty(uom) || isUndefined(uom?.value) || !uom?.uomCode) return '';

  return (
    <Translate
      stringId={`${uom?.uomCode}${short ? '_short' : ''}`}
      values={{ value: uom?.value }}
      data-testid={testId}
    />
  );
};

export const useUom = () => {
  const { formatMessage } = useIntl();

  const getUom = React.useCallback(
    quantityObject => {
      return formatMessage({ id: quantityObject.uomCode }, { value: quantityObject.value });
    },
    [formatMessage]
  );

  return { getUom, Uom };
};
